// components/ProviderWrapper.tsx
import React, {useState, useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {AuthProvider} from "./AuthContext";
import {ApiProvider} from "./ApiProvider";
import {ProductProvider} from "../context/ProductContext";
import {OutflowProvider} from "../context/OutflowContext";
import {UserProvider} from "../context/UserContext";
import {DispenseHistoryProvider} from "../context/DispenseHistoryContext";
import theme from "../theme";
import {
	AlertTitle,
	Alert,
	AlertIcon,
	Box,
	ChakraProvider,
	AlertDescription,
	Spinner,
} from "@chakra-ui/react";
import {BillProvider} from "../context/BillContext";
import {fetchCurrentUser} from "../api/userApi";
import {useAuth} from "./AuthContext";

const ProviderWrapper: React.FC<{children: React.ReactNode}> = ({children}) => {
	return (
		<ChakraProvider theme={theme}>
			<Router>
				<AuthProvider>
					<RootProvider>
						<DispenseHistoryProvider>
							<BillProvider>
								<ProductProvider>
									<OutflowProvider>
										{children}
									</OutflowProvider>
								</ProductProvider>
							</BillProvider>
						</DispenseHistoryProvider>
					</RootProvider>
				</AuthProvider>
			</Router>
		</ChakraProvider>
	);
};
const RootProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
	const {jwt, logout} = useAuth();
	const [currentUser, setCurrentUser] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const fetchCurrentUserData = async () => {
		if (!jwt) {
			setLoading(false);
			return;
		}
		setLoading(true);
		try {
			const userData = await fetchCurrentUser(jwt);
			setCurrentUser(userData);
		} catch (err) {
			logout();
			setError("Failed to fetch current user");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCurrentUserData();
	}, [jwt]);

	if (loading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
			>
				<Spinner size="xl" color="green.500" thickness="4px" />
			</Box>
		);
	}

	if (error) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
			>
				<Alert
					status="error"
					variant="subtle"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="200px"
				>
					<AlertIcon boxSize="40px" mr={0} />
					<AlertTitle mt={4} mb={1} fontSize="lg">
						Error
					</AlertTitle>
					<AlertDescription maxWidth="sm">{error}</AlertDescription>
				</Alert>
			</Box>
		);
	}

	return (
		<ApiProvider currentUser={currentUser}>
			<UserProvider
				currentUser={currentUser}
				fetchCurrentUserData={fetchCurrentUserData}
			>
				{children}
			</UserProvider>
		</ApiProvider>
	);
};

export default ProviderWrapper;
