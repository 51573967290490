import React, { useEffect, useState } from 'react';
import { Box, Heading, Spinner, Text, VStack, Flex, FormLabel } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispenseHistoryContext } from '../../context/DispenseHistoryContext';
import { useOutflowContext } from '../../context/OutflowContext';  // Adjust path as necessary
import { DispenseHistory } from '../dispense/DispenseInterfaces';  // Adjust path as necessary
import { Outflow } from '../outflows/OutflowInterfaces';  // Adjust path as necessary

interface PaymentMethods {
    [key: string]: number;
}

const CashFlowView: React.FC = () => {
    const { dispenseHistories, loading: loadingDispenses, error: errorDispense } = useDispenseHistoryContext();
    const { outflows, loading: loadingOutflows, error: errorOutflows } = useOutflowContext();

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethods>({});
    const [totalInflows, setTotalInflows] = useState<number>(0);
    const [totalOutflowsWithDebt, setTotalOutflowsWithDebt] = useState<number>(0);
    const [totalOutflowsWithoutDebt, setTotalOutflowsWithoutDebt] = useState<number>(0);
    const [personalDebt, setPersonalDebt] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const formatNumber = (num: number): string => new Intl.NumberFormat('en-US').format(num);

    const setStartOfDay = (date: Date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    };

    const setEndOfDay = (date: Date) => {
        const newDate = new Date(date);
        newDate.setHours(23, 59, 59, 999);
        return newDate;
    };

    const parseDate = (dateInput: string | number) => {
        if (typeof dateInput === 'number') {
            return new Date(dateInput);
        }
        const parts = dateInput.split('-');
        return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
    };

    const filterByDateRange = (data: (DispenseHistory | Outflow)[], startDate: Date | null, endDate: Date | null): (DispenseHistory | Outflow)[] => {
        if (!startDate || !endDate) return data;
        const start = setStartOfDay(startDate).getTime();
        const end = setEndOfDay(endDate).getTime();
        console.log('Filtering from:', start, 'to:', end);
        return data.filter(item => {
            const itemDate = item.hasOwnProperty('dateDispensed') 
                ? (typeof (item as DispenseHistory).dateDispensed === 'number' 
                    ? (item as DispenseHistory).dateDispensed 
                    : parseDate((item as DispenseHistory).dateDispensed).getTime())
                : new Date((item as Outflow).date).getTime();
            const isInRange = itemDate >= start && itemDate <= end;
            if (!isInRange) {
                console.log('Filtered out:', item);
            }
            return isInRange;
        });
    };

    useEffect(() => {
        if (!loadingDispenses && !errorDispense) {
            const filteredDispenses = filterByDateRange(dispenseHistories, startDate, endDate) as DispenseHistory[];
            const methods: PaymentMethods = filteredDispenses.reduce((acc: PaymentMethods, history: DispenseHistory) => {
                const method = history.medioDePago;
                acc[method] = (acc[method] || 0) + history.precio;
                return acc;
            }, {});
            const inflows = Object.values(methods).reduce((total: number, amount: number) => total + amount, 0);
            setPaymentMethods(methods);
            setTotalInflows(inflows);
        }
    }, [dispenseHistories, loadingDispenses, errorDispense, startDate, endDate]);

    useEffect(() => {
        if (!loadingOutflows && !errorOutflows) {
            const filteredOutflows = filterByDateRange(outflows, startDate, endDate) as Outflow[];
            const total = filteredOutflows.reduce((acc: number, outflow: Outflow) => acc + outflow.price, 0);
            const debt = filteredOutflows
                .filter(outflow => outflow.origin === 'Personal' && !outflow.isPaidToEmployee)
                .reduce((acc: number, outflow: Outflow) => acc + outflow.price, 0);

            const withoutDebt = total - debt;

            setTotalOutflowsWithDebt(total);
            setTotalOutflowsWithoutDebt(withoutDebt);
            setPersonalDebt(debt);
        }
    }, [outflows, loadingOutflows, errorOutflows, startDate, endDate]);

    if (loadingDispenses || loadingOutflows) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Spinner size="xl" />
            </Box>
        );
    }

    if (errorDispense || errorOutflows) {
        return <Box>Error: {errorDispense || errorOutflows}</Box>;
    }

    return (
        <Box>
            <Flex mb={4} alignItems="center">
                <Box mr={4}>
                    <FormLabel>Start Date:</FormLabel>
                    <DatePicker
                        selected={startDate}
                        onChange={(date: Date | null) => setStartDate(date)}
                        dateFormat="MM/dd/yyyy"
                        customInput={<Box as="input" borderWidth="1px" borderRadius="md" p={2} />}
                    />
                </Box>
                <Box>
                    <FormLabel>End Date:</FormLabel>
                    <DatePicker
                        selected={endDate}
                        onChange={(date: Date | null) => setEndDate(date)}
                        dateFormat="MM/dd/yyyy"
                        customInput={<Box as="input" borderWidth="1px" borderRadius="md" p={2} />}
                    />
                </Box>
            </Flex>
            <Heading as="h2" size="xl" mb={4}>
                Inflows
            </Heading>
            <VStack spacing={4} mb={8} align="stretch">
                {Object.entries(paymentMethods).map(([method, total]) => {
                    const percentage = ((total / totalInflows) * 100).toFixed(2);
                    return (
                        <Box key={method} bg="gray.100" p={4} borderRadius="md">
                            <Text fontWeight="bold">{method}</Text>
                            <Text>{`Total: ${formatNumber(total)}$ (${percentage}%)`}</Text>
                        </Box>
                    );
                })}
                <Box bg="gray.200" p={4} borderRadius="md">
                    <Text fontWeight="bold">Total Inflows</Text>
                    <Text>{`$${formatNumber(totalInflows)}`}</Text>
                </Box>
            </VStack>

            <Heading as="h2" size="xl" mb={4}>
                Outflows
            </Heading>
            <VStack spacing={4} mb={8} align="stretch">
                <Box bg="gray.200" p={4} borderRadius="md">
                    <Text fontWeight="bold">Total Outflows (Including Debt)</Text>
                    <Text>{`$${formatNumber(totalOutflowsWithDebt)}`}</Text>
                </Box>
                <Box bg="gray.200" p={4} borderRadius="md">
                    <Text fontWeight="bold">Total Outflows (Excluding Debt)</Text>
                    <Text>{`$${formatNumber(totalOutflowsWithoutDebt)}`}</Text>
                </Box>
                {personalDebt > 0 && (
                    <Box bg="red.100" p={4} borderRadius="md">
                        <Text fontWeight="bold" color="red.500">Unpaid Personal Debt</Text>
                        <Text>{`$${formatNumber(personalDebt)}`}</Text>
                    </Box>
                )}
            </VStack>
        </Box>
    );
};

export default CashFlowView;
