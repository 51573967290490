import React from "react";
import {
	Box,
	Flex,
	Input,
	Select,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {parseISO, format} from "date-fns";

interface AdvancedFilterInputProps<T> {
	field: keyof T;
	config: {
		label: string;
		filterType?: "string" | "date" | "enum" | "range" | "dateRange";
		filterOptions?: string[];
		filterMin?: number;
		filterMax?: number;
	};
	filters: Partial<Record<keyof T, any>>;
	handleFilterChange: (field: keyof T, value: any) => void;
	handleDateRangeFilterChange: (
		field: keyof T,
		part: "start" | "end",
		date: Date | null
	) => void;
}

function AdvancedFilterInput<T>({
	field,
	config,
	filters,
	handleFilterChange,
	handleDateRangeFilterChange,
}: AdvancedFilterInputProps<T>) {
	const handleDateChange = (date: Date | null, key: "start" | "end") => {
		// Date handling with fallback for undefined
		const formattedDate = date ? format(date, "yyyy-MM-dd") : undefined;
		const existingRange = filters[field] || {};
		handleFilterChange(field, {...existingRange, [key]: formattedDate});
	};

	const handleRangeChange = (value: number[]) => {
		// Handling range slider changes
		handleFilterChange(field, {min: value[0], max: value[1]});
	};

	return (
		<Box mb={4}>
			<Flex align="center" mb={2}>
				<Box mr={2} fontWeight="bold">
					{config.label}:
				</Box>
				{config.filterType === "date" && (
					<DatePicker
						selected={
							filters[field]
								? parseISO(filters[field] as string)
								: undefined
						}
						onChange={(date) => handleDateChange(date, "start")}
						dateFormat="yyyy-MM-dd"
						placeholderText="Select date"
					/>
				)}
				{config.filterType === "dateRange" && (
					<Flex>
						<DatePicker
							selected={
								(filters[field] as any)?.start
									? parseISO((filters[field] as any).start)
									: null
							}
							onChange={(date) =>
								handleDateRangeFilterChange(
									field,
									"start",
									date
								)
							}
							dateFormat="yyyy-MM-dd"
							minDate={new Date("2024-01-01")}
							placeholderText="Start date"
						/>
						<Box mx={2}>to</Box>
						<DatePicker
							selected={
								(filters[field] as any)?.end
									? parseISO((filters[field] as any).end)
									: null
							}
							onChange={(date) =>
								handleDateRangeFilterChange(field, "end", date)
							}
							dateFormat="yyyy-MM-dd"
							placeholderText="End date"
							minDate={new Date("2024-01-01")}
						/>
					</Flex>
				)}
				{config.filterType === "enum" && (
					<Select
						value={filters[field] as string | undefined}
						onChange={(e) =>
							handleFilterChange(field, e.target.value)
						}
						placeholder="Select option"
					>
						<option value="">All</option>
						{config.filterOptions?.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</Select>
				)}
				{config.filterType === "range" && (
					<Flex align="center" width="100%">
						<RangeSlider
							min={config.filterMin ?? 0}
							max={config.filterMax ?? 1000}
							value={[
								filters[field]?.min ?? config.filterMin,
								filters[field]?.max ?? config.filterMax,
							]}
							onChange={handleRangeChange}
							width="100%"
						>
							<RangeSliderTrack>
								<RangeSliderFilledTrack />
							</RangeSliderTrack>
							<RangeSliderThumb index={0} />
							<RangeSliderThumb index={1} />
						</RangeSlider>
						<Box ml={4}>
							{filters[field]?.min ?? config.filterMin} -{" "}
							{filters[field]?.max ?? config.filterMax}
						</Box>
					</Flex>
				)}
				{config.filterType !== "date" &&
					config.filterType !== "enum" &&
					config.filterType !== "range" &&
					config.filterType !== "dateRange" && (
						<Input
							value={filters[field] as string | undefined}
							onChange={(e) =>
								handleFilterChange(field, e.target.value)
							}
							placeholder={`Filter by ${config.label}`}
						/>
					)}
			</Flex>
		</Box>
	);
}

export default AdvancedFilterInput;
