import React, {useState, useMemo, useEffect} from "react";
import {
	AlertIcon,
	Alert,
	Box,
	Checkbox,
	Icon,
	Spinner,
	AlertDescription,
	AlertTitle,
	SimpleGrid,
	Stat,
	StatLabel,
	StatNumber,
} from "@chakra-ui/react";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import CustomizableTable from "../customizable-table/CustomizableTable";
import {useBillContext} from "../../context/BillContext";
import {useUserContext} from "../../context/UserContext";

const BillsView: React.FC = () => {
	const {bills, loading, error, updateBill} = useBillContext();
	const {users} = useUserContext();
	const [filteredSortedData, setFilteredSortedData] = useState<any[]>([]);

	useEffect(() => {
		if (bills.length > 0) {
			console.log('BillsView Initial Data Debug:', {
				platform: /Mobile/.test(navigator.userAgent) ? 'mobile' : 'desktop',
				sampleBill: {
					id: bills[0].id,
					originalDate: bills[0].publishedAt,
					formattedDate: new Date(bills[0].publishedAt).toLocaleString(),
					dateObject: new Date(bills[0].publishedAt),
					timestamp: new Date(bills[0].publishedAt).getTime()
				},
				totalBills: bills.length
			});
		}
	}, [bills]);

	const handleBilledToggle = async (
		billId: number,
		currentBilledStatus: boolean
	) => {
		try {
			await updateBill(billId, {isBilled: !currentBilledStatus});
		} catch (error) {
			console.error("Error updating bill status:", error);
		}
	};

	const fields: any = {
		id: {label: "Bill ID"},
		publishedAt: {
			label: "Date",
			filterType: "dateRange",
			filterField: "publishedAt"
		},
		price: {
			label: "Price",
		},
		"user.email": {label: "User Email", filterField: "user.email"},
		"user.dni": {label: "User DNI", filterField: "user.dni"},
		"user.name": {label: "User Name", filterField: "user.name"},
		isBilled: {
			label: "Billed",
			filterField: "isBilled",
			disableFilter: true,
		},
	};

	const getUser = (userId: number) => {
		return users.find((user: any) => user.id === userId);
	};

	const totals = useMemo(() => {
		const totalBilled = filteredSortedData.reduce(
			(total, bill) =>
				total + (bill.isBilled.props.isChecked ? bill.price : 0),
			0
		);

		const totalUnbilled = filteredSortedData.reduce(
			(total, bill) =>
				total + (!bill.isBilled.props.isChecked ? bill.price : 0),
			0
		);
		const totalAmount = filteredSortedData.reduce(
			(total, bill) => total + bill.price,
			0
		);

		return {totalBilled, totalUnbilled, totalAmount};
	}, [filteredSortedData]);

	const handleDataChange = (newData: any[]) => {
		setFilteredSortedData(newData);
	};

	// Memoize the mapped data to prevent recreating objects on every render
	const tableData = useMemo(() => bills.map((bill: any) => {
		console.log('BillsView Date Debug:', {
			billId: bill.id,
			originalDate: bill.publishedAt,
			formattedDate: new Date(bill.publishedAt).toLocaleString(),
		});
		
		// Store the date as a timestamp for filtering
		const date = new Date(bill.publishedAt);
		return {
			id: bill.id,
			publishedAt: date.getTime(),
			publishedAt_formatted: date.toLocaleDateString('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit'
			}),
			
			price: bill.price,
			user: {
				email: getUser(bill.user?.id)?.email || "",
				dni: getUser(bill.user?.id)?.dni || "",
				name: `${getUser(bill.user?.id)?.name} ${getUser(bill.user?.id)?.lastName}`,
			},
			isBilled: (
				<Checkbox
					isChecked={bill.isBilled}
					onChange={() => handleBilledToggle(bill.id, bill.isBilled)}
					icon={
						<Icon
							as={bill.isBilled ? CheckIcon : CloseIcon}
							color={bill.isBilled ? "green.500" : "red.500"}
						/>
					}
				/>
			),
		};
	}), [bills, users]); // Add users as dependency if getUser uses it

	return (
		<Box bg="white" p={4} borderRadius="lg" boxShadow="lg" overflowX="auto">
			{loading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100px"
				>
					<Spinner size="xl" color="green.500" thickness="4px" />
				</Box>
			) : error ? (
				<Alert
					status="error"
					variant="subtle"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="200px"
				>
					<AlertIcon boxSize="40px" mr={0} />
					<AlertTitle mt={4} mb={1} fontSize="lg">
						Error
					</AlertTitle>
					<AlertDescription maxWidth="sm">{error}</AlertDescription>
				</Alert>
			) : (
				<>
					<SimpleGrid columns={[1, 2, 3]} spacing={4} mb={4}>
						<Stat
							p={4}
							bg="white"
							borderRadius="lg"
							boxShadow="md"
							transition="all 0.3s"
							_hover={{
								transform: "translateY(-5px)",
								boxShadow: "lg",
							}}
						>
							<StatLabel
								fontSize="sm"
								fontWeight="medium"
								color="gray.500"
							>
								Total - Invoice Generated
							</StatLabel>
							<StatNumber
								fontSize="2xl"
								fontWeight="bold"
								color="green.500"
							>
								{totals.totalBilled.toLocaleString("en-US", {
									style: "currency",
									currency: "ARS",
								})}
							</StatNumber>
						</Stat>
						<Stat
							p={4}
							bg="white"
							borderRadius="lg"
							boxShadow="md"
							transition="all 0.3s"
							_hover={{
								transform: "translateY(-5px)",
								boxShadow: "lg",
							}}
						>
							<StatLabel
								fontSize="sm"
								fontWeight="medium"
								color="gray.500"
							>
								Total - Invoice Pending
							</StatLabel>
							<StatNumber
								fontSize="2xl"
								fontWeight="bold"
								color="red.500"
							>
								{totals.totalUnbilled.toLocaleString("en-US", {
									style: "currency",
									currency: "ARS",
								})}
							</StatNumber>
						</Stat>
						<Stat
							p={4}
							bg="white"
							borderRadius="lg"
							boxShadow="md"
							transition="all 0.3s"
							_hover={{
								transform: "translateY(-5px)",
								boxShadow: "lg",
							}}
						>
							<StatLabel
								fontSize="sm"
								fontWeight="medium"
								color="gray.500"
							>
								Total Amount
							</StatLabel>
							<StatNumber
								fontSize="2xl"
								fontWeight="bold"
								color="blue.500"
							>
								{totals.totalAmount.toLocaleString("en-US", {
									style: "currency",
									currency: "ARS",
								})}
							</StatNumber>
						</Stat>
					</SimpleGrid>
					<CustomizableTable
						data={tableData}
						fields={fields}
						onDataChange={handleDataChange}
					/>
				</>
			)}
		</Box>
	);
};

export default BillsView;
