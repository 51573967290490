import React from "react";
import {
	Box,
	SimpleGrid,
	Text,
	Image,
	Badge,
	useColorModeValue,
	AlertDescription,
	AlertTitle,
	Alert,
	Spinner,
	AlertIcon,
} from "@chakra-ui/react";
import ProductCard from "./ProductCard";
import {useProductContext} from "../../context/ProductContext";

const ProductsView = () => {
	const {products, loading, error} = useProductContext();

	if (loading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100px"
			>
				<Spinner size="xl" color="green.500" thickness="4px" />
			</Box>
		);
	}
	if (error) {
		return (
			<Alert
				status="error"
				variant="subtle"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				height="200px"
			>
				<AlertIcon boxSize="40px" mr={0} />
				<AlertTitle mt={4} mb={1} fontSize="lg">
					Error
				</AlertTitle>
				<AlertDescription maxWidth="sm">{error}</AlertDescription>
			</Alert>
		);
	}

	return (
		<Box padding="5">
			<Text fontSize="2xl" mb="5">
				Our Products
			</Text>
			<SimpleGrid columns={{sm: 2, md: 3, lg: 4}} spacing="5">
				{products.map((product) => (
					<ProductCard key={product.id} product={product} />
				))}
			</SimpleGrid>
		</Box>
	);
};

export default ProductsView;
