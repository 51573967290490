import React, {useEffect, useState} from "react";
import {
	AlertIcon,
	Alert,
	Box,
	Checkbox,
	Icon,
	Spinner,
	useDisclosure,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import CustomizableTable from "../customizable-table/CustomizableTable";
import {useOutflowContext} from "../../context/OutflowContext";
import OriginModal from "./OriginModal";

const OutflowsView: React.FC = () => {
	const {outflows, loading, error, updateOutflow} = useOutflowContext();
	const [maxPrice, setMaxPrice] = useState(0);
	const {isOpen, onOpen, onClose} = useDisclosure();
	const [selectedOutflowId, setSelectedOutflowId] = useState<number | null>(
		null
	);
	const [togglePaidStatus, setTogglePaidStatus] = useState<boolean>(true); // Track the desired paid status

	useEffect(() => {
		if (outflows.length > 0) {
			const max = Math.max(...outflows.map((o) => o.price));
			setMaxPrice(max);
		}
	}, [outflows]); // Recalculate whenever outflows change

	const handlePaidToggle = (
		outflowId: number,
		currentPaidStatus: boolean
	) => {
		setSelectedOutflowId(outflowId);
		setTogglePaidStatus(!currentPaidStatus);
		if (currentPaidStatus) {
			// If currently paid, revert to Personal and set paid to false
			handleSubmitOrigin("Personal", false);
		} else {
			// If currently unpaid, open the modal to select the origin and set paid to true
			onOpen();
		}
	};

	const handleSubmitOrigin = async (
		origin: string,
		isPaidToEmployee: boolean = true
	) => {
		if (selectedOutflowId !== null) {
			try {
				await updateOutflow(selectedOutflowId, {
					origin,
					isPaidToEmployee,
				});
			} catch (error) {
				console.error("Error updating outflow status:", error);
			}
		}
		onClose();
	};

	const fields: any = {
		id: {label: "Outflow ID", disableFilter: true},
		date: {label: "Date", filterType: "dateRange"},
		price: {
			label: "Price",
			filterType: "range",
			filterMin: 0,
			filterMax: maxPrice,
		}, // Use state variable for dynamic max value
		concept: {label: "Concept", disableFilter: true},
		entity: {label: "Entity", disableFilter: true},
		cuit: {label: "CUIT", disableFilter: true},
		billType: {
			label: "Bill Type",
			filterType: "enum",
			filterOptions: ["A", "B", "C"],
		},
		origin: {
			label: "Origin",
			filterType: "enum",
			filterOptions: ["MercadoPago", "Transferencia", "Cash", "Personal"],
		},
		user: {label: "User", disableFilter: true}, // Add user field
	};

	const outflowData = outflows.map((outflow: any) => ({
		id: outflow.id,
		date: new Date(outflow.date).toLocaleString(),
		price: outflow.price,
		concept: outflow.concept,
		entity: outflow.entity,
		cuit: outflow.cuit,
		billType: outflow.billType,
		origin: outflow.origin,
		user: outflow.user ? outflow.user.username : "", // Access nested username
		fullUser: outflow.user,
		isPaidToEmployee: outflow.isPaidToEmployee,
	}));

	const hasEmployeeRole = (outflow: any) => {
		console.log(outflow);
		const hasUser = outflow && outflow.user != undefined;
		console.log(hasUser);
		if (hasUser) console.log(outflow.fullUser);

		return (
			hasUser &&
			(outflow.fullUser.role.name === "Employee" ||
				outflow.fullUser.role.name === "Admin")
		);
	};

	const filteredFields = {...fields};

	filteredFields.isPaidToEmployee = {
		label: "Paid",
		filterField: "isPaidToEmployee",
	};

	return (
		<Box bg="white" p={4} borderRadius="lg" boxShadow="lg" overflowX="auto">
			{loading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100px"
				>
					<Spinner size="xl" color="green.500" thickness="4px" />
				</Box>
			) : error ? (
				<Alert
					status="error"
					variant="subtle"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="200px"
				>
					<AlertIcon boxSize="40px" mr={0} />
					<AlertTitle mt={4} mb={1} fontSize="lg">
						Error
					</AlertTitle>
					<AlertDescription maxWidth="sm">{error}</AlertDescription>
				</Alert>
			) : (
				<>
					<CustomizableTable
						data={outflowData.map((outflow: any) => ({
							id: outflow.id,
							date: new Date(outflow.date).toLocaleString(),
							price: outflow.price,
							concept: outflow.concept,
							entity: outflow.entity,
							cuit: outflow.cuit,
							billType: outflow.billType,
							origin: outflow.origin,
							user: outflow.user,
							...(hasEmployeeRole(outflow) && {
								isPaidToEmployee: (
									<Checkbox
										isChecked={outflow.isPaidToEmployee}
										onChange={() =>
											handlePaidToggle(
												outflow.id,
												outflow.isPaidToEmployee
											)
										}
										icon={
											<Icon
												as={
													outflow.isPaidToEmployee
														? CheckIcon
														: CloseIcon
												}
												color={
													outflow.isPaidToEmployee
														? "green.500"
														: "red.500"
												}
											/>
										}
									/>
								),
							}),
						}))}
						fields={filteredFields}
					/>
					<OriginModal
						isOpen={isOpen}
						onClose={onClose}
						onSubmit={(origin) => handleSubmitOrigin(origin, true)}
					/>
				</>
			)}
		</Box>
	);
};

export default OutflowsView;
